import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import styled from 'styled-components'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import ReadyToGetStarted from '../components/ReadyToGetStarted/ReadyToGetStarted'
import Heading2 from '../components/Type/Heading2'
import ListingLinks from '../components/ListingLinks/ListingLinks'


const RecentListingsPage = ({location}) => (
	<StaticQuery
		query={graphql`
			query  {
				allContentfulCarListing (filter: {sold: {eq: true}}, sort: {fields: [carName]}) {
					edges {
					  node {
						carName
						sold
						price
						carSalesAdId
						location
						pipedriveDealId
						slug
						vin
						detailsPage
						id
						description {
						  description
						}
						listingIndexImage {
						  fixed(width: 300, height: 200) {
							...GatsbyContentfulFixed_noBase64
						  }
						}
						photos {
							fixed(width: 300, height: 200) {
								...GatsbyContentfulFixed_noBase64
							  }
						  }
					  }
					}
				  }

			}
		`}

		render={data => {

			const listings = data.allContentfulCarListing.edges.map((item, i) => {

				let link = "Sold";
				if (item.node.detailsPage === true) link = <Link to={'/listings/' + item.node.slug + "-" + item.node.pipedriveDealId + "/"}>View Details</Link>

				// fixed={item.node.listingIndexImage.fixed}
				// hack until content is done
				let image = item.node.listingIndexImage; //|| item.node.photos[0] || null;

				//if (item.node.listingIndexImage && item.node.description.description) {
				if (image && item.node.description.description) {

					return (

						<Card key={i}>
							<div style={{ position: 'relative' }}>
								<Img
									fixed={image.fixed}
									style={{
										width: '300px',
										height: '200px'
									}}
								/>


							</div>

							<Container>
								<Heading>{item.node.carName}</Heading>
								<Description>{item.node.description.description}</Description>

							</Container>

							<StatusLink>{link}</StatusLink>

						</Card>
					)

				} else {
					return null;
				}

			}
			);

			// 	 We have new cars coming all the time, 
			// so follow us on Facebook or Twitter to be notified of new listings. If you have a particular 
			// car you are looking to buy, please contact us as we often have off-market listings available."

			return (

				<Layout>

					<SEO title="Summon Sold Listings"
						description="Summon luxury and prestige car listings"
						location={location}
					/>

					<PageHeader
						title="Prestige car listings"
						text="Below are prestige cars that we have recently sold privately on behalf of their owners." 
					
					/>

					<ListingLinks />


					<SectionPageMargin>
						<Heading2>
							Luxury &amp; prestige cars sold
						</Heading2>

						<BackgroundSection>
							{listings}
						</BackgroundSection>
					</SectionPageMargin>

					<ReadyToGetStarted />

				</Layout>

			)
		}}
	/>
)

export default RecentListingsPage


const BackgroundSection = styled.section`
    display: flex;
    flex-flow: row wrap;
	margin-top: 30px;
	//justify-content: space-between;

	@media (max-width: 650px) {
		justify-content: center;
	  } 

`;


const Card = styled.div`
display: grid;
grid-template-rows: 200px auto 40px;

  background-color:  white;
  color: black;
  width: 300px;
  height: 400px;
  margin: 10px 30px 30px 0px;
  padding: 0px;

&:hover {
     box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
   }
`;

const Container = styled.div`
background: #f8f8f9;
  padding: 5px;
  text-align: center;
  
  p {
    color: black;
    margin: 0px;
    padding: 0px;
  }

  a {
    color: black;
  }
`;

const Heading = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.2em;
`;

const Description = styled.div`
  colour: dark-grey;
  margin: 0px;
  padding: 0px 10px 0px 10px;
  font-size: .9rem;
`;
const StatusLink = styled.button`
  background: #f8f8f9;
  width: 100%;
  height: 40px;
  color: black;

  padding: 0px;
  font-size: 1em;
  text-decoration: none;
  border: none;
  border-top: white 6px solid;
  outline: none;
  text-align: center;


`;





