import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import SectionPageMargin from '../Layout/Section/SectionPageMargin'


const ListingLinks = () => {
    return (
        <SectionPageMargin marginBottom="0px">
            <strong style={{marginRight: '10px'}}>LISTINGS: </strong>
            <StyledLink to="/recent-listings/">For Sale</StyledLink> 
            <StyledLink to="/listings-sold/">Sold</StyledLink>  
            {/* <StyledLink to="/off-market/">Off Market</StyledLink> */}
        </SectionPageMargin>
    )
};
export default ListingLinks

const StyledLink = styled(Link)`
 font-weight: bold;
 text-decoration: none;
 color: #3479ae;
 margin-right: 15px;

 &:hover {
    opacity: 0.8;
  }


`
